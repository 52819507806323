<template>
  <div>
    <overview :state="state" />
    <backlog :state="state" :restChart="restChart" />
    <totalOrder :state="state" :type='type' />
    <discount :sevenDataArr="sevenDataArr" />
  </div>
</template>

<script>
import overview from "../myStatistics/components/overview";
import backlog from "../myStatistics/components/backlog";
import totalOrder from "../myStatistics/components/totalOrder";
import discount from "../myStatistics/components/discount";
import { getPcCount, countCustomerAnalysis, sevenData} from "@/api/myStatistics";
import {getDataTimeSec} from "@/utils/index"
export default {
  name: "enterprise",
  data() {
    return {};
  },
  components: {
    overview,
    backlog,
    totalOrder,
    discount,
  },
  props: {
    type: {
      type: Number,
      default: 1,
    },
  },
  created() {
    this.getPcCount();
    this.countCustomerAnalysis();
    this.sevenData();
  },
  data() {
    return {
      state: {},
      restChart: [],
      sevenDataArr:[]
    };
  },
  methods: {
    async getPcCount() {
      const result = await getPcCount({ type: this.type });
      if(result.code == 204){
        return
      }
      if(result.data.newCustomerList){
        result.data.newCustomerList.map(val =>{
          val.createTime = getDataTimeSec(val.createTime)
        }) 
      }

      this.state = result.data;
    },
    async countCustomerAnalysis() {
      const result = await countCustomerAnalysis({ type: this.type });
      this.restChart = result.data;
    },
    async sevenData() {
      const result = await sevenData({ type: this.type });
      const { orderList, dealList } = result.data;
      if(orderList.length > 0){
        orderList.map(item =>{
          if(item.amount){
            item.amount = (item.amount/100).toFixed(2)
          }
        })
      }
      if(dealList.length > 0){
        dealList.map(item =>{
          if(item.amount){
            item.amount = (item.amount/100).toFixed(2)
          }
        })
      }
      orderList.map((item) => {
        item.city = "商城订单";
      });
      dealList.map((item) => {
        item.city = "个人订单";
      });
      
      this.sevenDataArr = orderList.concat(dealList);
      console.log(this.sevenDataArr,'1111')
    },
  },
};
</script>

<style lang="scss" scoped>
</style>